import { useReducer } from 'react';
import useInput from '../hooks/useInput.js';
import {
  useFilters,
  FilterItemBuilder,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import { getCBOs, getLocations } from '../api/api.js';

import { fetchUserReport } from '../api/users.ts';
import ReportPageTemplate from '../components/Reports/ReportPageTemplate.js';

/**
 * @typedef {Object} AccountExportedObject
 * @property {string|null} cbo_name - The name of the CBO.
 * @property {boolean|null} cbo_activity - The activity status of the CBO.
 * @property {string|null} loc_name - The name of the location.
 * @property {boolean|null} loc_activity - The activity status of the location.
 * @property {number|null} location_id - The ID of the location.
 * @property {string|null} acct_name - The name of the account.
 * @property {string|null} acct_username - The username of the account.
 * @property {string|null} acct_role - The role of the account.
 * @property {Date|null} acct_lastlogin - The last login time of the account.
 * @property {string|null} loc_pc - The primary contact username for the location.
 * @property {string|null} loc_sc - The secondary contact username for the location.
 * @property {string|null} loc_address - The address of the location.
 * @property {string|null} loc_phone - The phone number of the location.
 * @property {string|null} loc_comments - Comments related to the location.
 * @property {string|null} acct_phone - The phone number associated with the account.
 * @property {Date|null} acct_active_since - The date the account has been active since.
 * @property {string|null} acct_status - The current status of the account.
 * @property {string|null} loc_embossing - The extra embossing of the location
 * @property {string|null} acct_email - The account's email
 * @property {boolean} is_csv - Whether the result is a CSV export (always true).
 */

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

const AccountExport = () => {
  const filters = useFilters(
    (filterValuesRef) => {
      return {
        last_activity_start: new FilterItemBuilder()
          .setLabel('Last Activity Start Date')
          .setParamName('last_activity_start')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(
            () => true,
            useInput
          ),
        username: new FilterItemBuilder()
          .setLabel('User Name')
          .setParamName('username')
          .setType('string')
          .useBuildCombo((v) => true, useInput
        ),
        name: new FilterItemBuilder()
          .setLabel('Name')
          .setParamName('name')
          .setType('string')
          .useBuildCombo((v) => true, useInput
        ),
        role: new FilterItemBuilder()
          .setLabel('Role')
          .setParamName('role')
          .select()
          .setOptions([
            { value: 'Administrator', label: 'Administrator' },
            { value: 'Super Coordinator', label: 'Super Coordinator' },
            { value: 'Coordinator', label: 'Coordinator' },
          ])
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        cbo_id: new FilterItemBuilder()
          .setLabel('CBO')
          .setParamName('cbo_id')
          .select()
          .setReference()
          .setGetterFunc(getCBOs)
          .setValueAndLabelParams('id', 'name')
        .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        location_id: new FilterItemBuilder()
          .setLabel('Locations')
          .setParamName('location_id')
          .select()
          .setReference()
          .setGetterFunc(getLocations)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
      };
    },
    []
  );

  const readableBoolean = (b) => b ? 'Yes' : 'No';

  /**
   * @param {AccountExportedObject} acct 
   */
  const csv_formatter = (acct) => ({
    'CBO': acct.cbo_name,
    'CBO Active': readableBoolean(acct.cbo_activity),
    'LocationId': acct.location_id,
    'Name': acct.loc_name,
    'Primary Contact User': acct.loc_pc,
    'Backup Contact User': acct.loc_sc,
    'Address': acct.loc_address,
    'Extra Embossing': acct.loc_embossing,
    'Location Phone': acct.loc_phone,
    'Enabled Cards': 'TODO',
    'Comments': acct.loc_comments,
    'Location Active': readableBoolean(acct.loc_activity),
    'Username': acct.acct_username,
    'Real Name': acct.acct_name,
    'Email': acct.acct_email,
    'User Level name': acct.acct_role,
    'Phone': acct.acct_phone,
    'Last Activity Date': acct.acct_lastlogin,
    'Is Locked Out': 'legacy',
    'Create Date': acct.acct_active_since,
    'Account Status': acct.acct_status

  })


  return (
    <>
      <ReportPageTemplate
        name="Account Export"
        description="Large export of all CBOs, Locations and Accounts."
        filters={filters}
        fetchRequest={fetchUserReport}
        fileName={"account export"}
        csvDownloadMappingFunction={csv_formatter}
        columns={[
          {
            accessorKey: 'cbo_name',
            header: 'CBO Name / Status',
          },
          {
            accessorKey: 'loc_name',
            header: 'Location Name / ID',
          },
          {
            accessorKey: 'acct_name',
            header: 'User Name / Username',
          },
          {
            accessorKey: 'acct_role',
            header: 'User Level',
          },
          {
            accessorKey: 'acct_lastlogin',
            header: 'Last Activity',
          }
        ]}
      />
    </>
  );
};

export default AccountExport;
